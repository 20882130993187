.App {
  text-align: center;
}

.App-logo {
  width: 140px;
  padding: 10px;
  pointer-events: none;
  float: right;
}
.App-title {
  color: #006EC7;
  float: left;
  font-size: calc(14px + 2vmin);
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "Open Sans","Arial",sans-serif;
}

.topBar {
  background: #f4f4f4;
  position: relative;
  width: 100%;
  box-shadow: 0 15px 12px -10px rgb(0 0 0 / 20%), 0 -15px 12px -10px rgb(0 0 0 / 20%);
}

@page { 
  size:210mm 297mm; 
  
}
@media print {
  .messageBar {
    display: none;
    
  }
  .MuiGrid-container {
    page-break-inside: avoid;
  }
  
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(17, 17, 17);
}

.App-link {
  color: #eca438;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.SimpleList{
  list-style-type: none;
}
.messageBar {
  width: 100%;
  display: block;
  margin-top: 17px;
  margin-bottom: 20px;
}
.legend_outer {
  list-style-type: none;
  display: flex;
}
.legend_list {
  margin-right: 7px;
  margin-left: 7px;
}
.legend {
  display: inline-block;width:20px;height:10px;border: 1px solid black;margin-right: 3px;
}
.legend_0 {
  background-color: #ffffff;
}
.legend_0_1 {
  background-color: #E1E2E2;
}
.legend_0_2 {
  background-color: #C8C9C9;
}
.legend_0_5 {
  background-color: #AEAFAF;
}
.legend_1 {
  background-color: #959696;
}
.legend_1g {
  background-color: #7B7C7C;
}
.legend_text {
  display: inline-flex;
}